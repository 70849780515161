<template>
    <div class="small_red_packet" v-show="fixed_red_packet_switch">
        <header></header>
        <section>
            <!-- 遮罩 -->
            <div class="shade_box_" @click="closePopup"></div>
            <div class="red_packet_box_">
                <h1 class="h1_title_">恭喜获得红包！</h1>
                <p class="content_text_">50</p>
                <p class="hint_text_">{{info_text}}</p>
                <div class="count_down_box_">
                    <van-count-down :time="counttime" millisecond>
                        <template #default="timeData">
                            <span class="block">{{ timeData.hours }}</span>
                            <span class="colon">:</span>
                            <span class="block">{{ timeData.minutes }}</span>
                            <span class="colon">:</span>
                            <span class="block">{{ timeData.seconds }}</span>
                            <span class="colon">:</span>
                            <span class="block">{{ parseInt(timeData.milliseconds / 10) }}</span>
                            <span class="colon text">后失效</span>
                        </template>	
                    </van-count-down>
                </div>
                <div class="button button_1_" @click="redPacketPayment"><img src="https://img.nnabc.cn/WechatIMG284.jpeg" alt=""></div>
            </div>
        </section>
        <header></header>
    </div>
</template>

<script>
import axios from "../utils/request";
export default {
    data() {
        return {
            fixed_red_packet_switch:false,//红包开关
            counttime:10*60*1000,//红包倒计时
            info_text:'',
            price:{}
        }
    },
    created () {
        this.getPricePort()
    },
    methods:{
        init() {
            switch (this.$route.path.split('/')[1]) {
                case 'aitestname':
                    this.info_text = '可减价查看姓名测试结果'
                    break;
                
            
                default:
                    break;
            }
            
          
        },
         getPricePort() {
            let from = this.$route.query.from || 10;
             axios.get(`/getSalesPrice?fromk=${from}`).then((res) => {
                let priceObj = res.data.data[0];
                this.price = res.data.data[0];
                if ( priceObj.discount_amount === '0.00' || priceObj.discount_amount === null){
                    let arr = ['zgqwpdhy1','zgqwpdtl1','zgqwpdys1','zgqwpdhy2','zgqwpdtl2','zgqwpdys2','zgqwpdcm1','zgqwhypd1','zgqwhyys1','zgqwhypd2','zgqwhytl2','zgqwhyys2','zgqwhycm1'];
                    if( arr.indexOf(this.$route.query.from) !== -1) { 
                        if( sessionStorage.getItem('is_fixed_red_packet') === 'true' ){
                            this.fixed_red_packet_switch = false;
                        }else{
                            this.fixed_red_packet_switch = true;
                        }
                    }else{
                        sessionStorage.setItem('is_fixed_red_packet',false)
                    }
                        
                }else{
                    sessionStorage.setItem('is_fixed_red_packet',false)
                    this.fixed_red_packet_switch = false;
                }
                this.init()
            });
          
        //  $getSalesPrice({fromk:this.$route.query.from}).then(( res ) => {
        //     let priceObj = res.data.data[0];
        //     this.price = res.data.data[0];
        //     if ( priceObj.discount_amount === '0.00' || priceObj.discount_amount === null){
        //         let arr = ['zgqwpdhy1','zgqwpdtl1','zgqwpdys1','zgqwpdhy2','zgqwpdtl2','zgqwpdys2','zgqwpdcm1','zgqwhypd1','zgqwhyys1','zgqwhypd2','zgqwhytl2','zgqwhyys2','zgqwhycm1'];
        //         if( arr.indexOf(this.$route.query.from) !== -1) { 
        //             if( sessionStorage.getItem('is_fixed_red_packet') === 'true' ){
        //                 this.fixed_red_packet_switch = false;
        //             }else{
        //                 this.fixed_red_packet_switch = true;
        //             }
        //         }else{
        //             sessionStorage.setItem('is_fixed_red_packet',false)
        //         }
                    
        //     }else{
        //         sessionStorage.setItem('is_fixed_red_packet',false)
        //         this.fixed_red_packet_switch = false;
        //     }
        //     this.init()
        //  }) 
        },
       redPacketPayment(){
            sessionStorage.setItem('is_fixed_red_packet',true)
            this.closePopup()
            this.$toast.success('红包领取成功');
            location.reload();
       },
      
        closePopup() {
            this.fixed_red_packet_switch = false
        }
    }
}
</script>

<style lang="scss" scoped>
    .small_red_packet{
        section{
            .shade_box_{
                position: fixed;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: rgba(0,0,0,.5);
                z-index:100;
            }
            .red_packet_box_{
                display: flex;
                white-space: nowrap;
                flex-direction: column;
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                width: 5.25rem;
                height: 8.23rem;
                background: url('https://img.nnabc.cn/WechatIMG283.png') no-repeat;
                background-size: 100% 100%;
                z-index: 101;
                .h1_title_{
                    text-align: center;
                    margin-top: 1rem;
                    color: #E38000;
                    font-size: .35rem;
                    margin-bottom: 0;
                }
                .content_text_{
                    text-align: center;
                    font-weight: 600;
                    margin-top: 0.2rem;             
                    font-size: 1rem;
                    color: red;
                    margin-bottom: 0;
                    &::after{
                        content: "元";
                        font-weight: 400;       
                        font-size: .35rem;
                    }
                }
                .hint_text_{
                    text-align: center;
                    margin-top: 0.2rem;
                    font-size: .25rem;
                    color: #c37d3f;
                }
                .count_down_box_{
                    text-align: center;
                    margin-top: 1.6rem;
                    .colon {
                        display: inline-block;
                        margin: 0 .04rem;
                        font-size: .2rem;
                        color: #fff;
                    }
                    .block {
                        display: inline-block;
                        width: .43rem;
                        color: #fff;
                        font-size: .24rem;
                        text-align: center;
                        background-color: #b63437;
                        box-sizing:border-box;
                        border: 1px solid #fa455f;
                    }
                    .text{
                        margin-left: .1rem;
                        font-size: .2rem;
                    }
                }
                .button{
                    display: block;
                    width: 3.51rem;
                    color: #f7b352;
                    font-size: .42rem;
                    margin: auto;
                    img{
                        display: block;
                        width: 100%;
                    }
                }
                .button_1_{
                    margin-top: .7rem;
                }
                .button_2_{
                    height: 0.81rem;
                    
                    border: 1px solid #d49c46;
                    border-radius: 0.81rem;
                    background: rgba(255, 255, 255, 0);
                    line-height: .81rem;
                    margin-top: -0.4rem;
                }
            }
        }
    }
</style>


