<template>
    <div class="testname" id="agentTitle">
	    <Imgbox  v-if="!checking"  src="https://img.nnabc.cn/202303241426.jpg" />
		<div v-if="is_fixed_red_packets">
                    <div class="red_packet_payment_text_">您已领到 50.00元 的优惠红包</div>
                    <div class="red_packet_payment_text2_">下方输入姓名即可享受  距活动结束剩 <CountDowns class="CountDown_Componet"/></div>
                    <!-- <div class="red_packet_payment_text2_">下方输入姓名即可享受  距活动结束剩 </div> -->
                    <div class="red_packet_icon_"><img src="https://img.nnabc.cn/1.gif" alt=""></div>
                    <div class="red_packet_frame">
                        <img src="https://img.nnabc.cn/WechatIMG3555.png" alt="">
                    </div>
                </div>
		<div v-else style="height: 30px;"></div>
	    <Card  class="formcard">
			<van-form @submit="onSubmit" validate-trigger="onSubmit" @failed="valifail">
				<van-field v-model="Form.username" size="mid" name="username" placeholder="请输入姓名"
					:rules="[{ validator }]">
					<template #label>
						<div class="labelclass">
							您的姓名
						</div>
					</template>
				</van-field>
				<van-field size="mid" name="sex">
					<template #label>
						<div class="labelclass">
							性&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别
						</div>
					</template>
					<template #input>
						<van-radio-group v-model="Form.sex" direction="horizontal">
							<van-radio name="1" icon-size="18" checked-color="#B95353">男</van-radio>
							<van-radio name="2" icon-size="18" checked-color="#B95353">女</van-radio>
						</van-radio-group>
					</template>
				</van-field>
				<van-field name="borth" size="mid" v-model="boyStr" :rules="[{ required: true}]">
					<template #label>
						<div class="labelclass">出生时间</div>
					</template>
					<template #input>
						<span v-if="boyStr" @click="pickdata">{{boyStr }}</span>
						<span class="llable" v-else @click="pickdata">{{label }}</span>
					</template>
				</van-field>
				<!-- <div class="center" style="font-size: 10px;color: #888;margin-top: 12px;" >
					本表单提交代表您已允许我们收集相关信息，且仅用作相关广告内容服务
				</div> -->
				<div   class="tip">- 仅需9s 给你名字分数 揭晓名字运势 -</div>
				<!-- 表单提交 -->
				<div class="center">
					<van-button class="subbutton" size="large" color="#B95353" type="info" round
						@click="payclick('v1_testname_top_form')" native-type="submit">
						立即测试</van-button>
				</div>
				<div class="p_text_" v-if="is_fixed_red_packets">您的特惠红包已激活 距离结束剩 <CountDowns :color="'#C94B4B'" /> <br><span class="info_text">（若有效期内未使用，即视为自动放弃本权益）</span></div>

				<div v-if="isbjks || banhao" class="center tip">
					<van-checkbox v-model="checked" icon-size="16px" shape="square" checked-color="#b95353" style="margin-right:5px"></van-checkbox>
					提交即视为同意收集相关信息，<span @click="lookrules = true" style="text-decoration: underline; color:#1122aa">个人隐私协议</span>
				</div>

				<div  v-if="!checking && !ifvo && !banhao "  class="center tip">
					已为<span class="color-red" style="font-weight:bold">&nbsp;9,761,918&nbsp;</span>人测试姓名，准确率很高
				</div>

				<div v-if="!checking && !ifvo && !banhao"    class="actionScroll">
					<vue-seamless-scroll :data="listData" :class-option="defaultOption" class="warp">
						<ul class="scrollbox">
							<li class="scrollitem" v-for="(item, index) in listData" :key="index">
								<span class="title123" v-text="item.time_unit"></span>
								<span class="region" style="width: 40px;" v-text="item.city"></span>
								<span class="name1" v-text="item.uname"></span>
								<span class="date" v-text="item.action"></span>
							</li>
						</ul>
					</vue-seamless-scroll>
				</div>
			</van-form>
		</Card>
		<div  v-if="is_show">
			<div class="center tith">- 姓名测试后你将了解如下信息 -</div>
			<Card class="img"  v-if="!checking">
				<Imgbox src="http://cdn1.zhiyileiju.cn/WechatIMG114.jpeg"></Imgbox>
			</Card>
			<Card v-if="!checking" class="img">
				<Imgbox src="http://cdn1.zhiyileiju.cn/WechatIMG113.jpeg"></Imgbox>
			</Card>
			<Card class="img">
				<Imgbox src="http://cdn1.zhiyileiju.cn/1071632902125_.pic_hd.jpg"></Imgbox>
			</Card>
			 <Card class="img">
				<Imgbox src="http://cdn1.zhiyileiju.cn/WechatIMG111.jpeg"></Imgbox>
			</Card>
		</div>
         <!-- <Card  v-if="!checking"  class="img">
			<Imgbox src="http://cdn1.zhiyileiju.cn/WechatIMG110.jpeg"></Imgbox>
		</Card> -->
         <!-- <Card   v-if="!checking && !ifvo " class="img">
			<Imgbox src="http://cdn1.zhiyileiju.cn/WechatIMG115.jpeg"></Imgbox>
		</Card> -->
         <Card class="formcard" style="margin-top:20px;">	
             <div class="center" style="padding:16px;font-size:16px;font-weight:bold;color:#C94B4B">姓名测试姓名打分</div>
			<van-form @submit="onSubmit" validate-trigger="onSubmit" @failed="valifail">
				<van-field v-model="Form.username" size="mid" name="username" placeholder="请输入姓名"
					:rules="[{ validator }]">
					<template #label>
						<div class="labelclass">
							您的姓名
						</div>
					</template>
				</van-field>
				<van-field size="mid" name="sex">
					<template #label>
						<div class="labelclass">
							性&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别
						</div>
					</template>
					<template #input>
						<van-radio-group v-model="Form.sex" direction="horizontal">
							<van-radio name="1" icon-size="18" checked-color="#B95353">男</van-radio>
							<van-radio name="2" icon-size="18" checked-color="#B95353">女</van-radio>
						</van-radio-group>
					</template>
				</van-field>
				<van-field name="borth" size="mid" v-model="boyStr" :rules="[{ required: true}]">
					<template #label>
						<div class="labelclass">出生时间</div>
					</template>
					<template #input>
						<span v-if="boyStr" @click="pickdata">{{boyStr }}</span>
						<span class="llable" v-else @click="pickdata">{{label }}</span>
					</template>
				</van-field>
				<!-- <div class="center" style="font-size: 10px;color: #888;margin-top: 12px;" >
					本表单提交代表您已允许我们收集相关信息，且仅用作相关广告内容服务
				</div> -->
				<div   class="tip">- 仅需9s 给你名字分数 揭晓名字运势 -</div>
				<!-- 表单提交 -->
				<div class="center">
					<van-button class="subbutton" size="large" color="#B95353" type="info" round
						@click="payclick('v1_testname_bottom_form')" native-type="submit">
						立即测试</van-button>
				</div>
				<div v-if="isbjks || banhao" class="center tip">
					<van-checkbox v-model="checked" icon-size="16px" shape="square" checked-color="#b95353" style="margin-right:5px"></van-checkbox>
					提交即视为同意收集相关信息，<span @click="lookrules = true" style="text-decoration: underline; color:#1122aa">个人隐私协议</span>
				</div>
				<div  v-if="!checking && !ifvo && !banhao"   class="center tip">
					已为<span class="color-red" style="font-weight:bold">&nbsp;9,761,918&nbsp;</span>人测试姓名，准确率很高
				</div>
			</van-form>
		</Card>
        <van-button :class="fixedbtn == true ? 'isFixed' : ''" size="large" color="#B95353" type="info"
			native-type="submit" @click="bottombtnclick">
			立即测试姓名
		</van-button>
        <rui-datepick :date="currentDate" v-model="boyShow" @confirm="boyConfirm" />
		
		<div  v-if="isbjks"  class="webfooter" style="height:160px;font-size:10px;color:#323232">
			<div>版权所有 上海容铭信息科技有限公司</div>
			<div>公司地址：上海市杨浦区抚顺路360号</div>
			<div>电话：18975441215</div>
		</div>
		<div v-else-if="isks4"  class="webfooter" style="height:160px;font-size:10px;color:#323232">
			<div>版权所有   武汉道森梯媒文化传播有限公司</div>
			<div>地址：武汉市武昌区徐东大街128号</div>
			<div>电话：18975441215</div>
		</div>
		<div v-else-if="ifFqudao"  class="webfooter" style="height:160px;font-size:10px;color:#323232">
			<div>版权所有   苏州特诺维信息技术有限公司</div>
			<div>地址：苏州工业园区星湖街328号创业产业园A1-16</div>
			<div>电话：15695165587</div>
		</div>
		<div v-else-if="ifvo"  class="webfooter" style="height:160px;font-size:10px;color:#323232">
			<div>版权所有   深圳市大白网络科技有限公司 </div>
			<div>粤ICP备19105866号</div>
			<div>电话：15695165587</div>
			<div v-if="this.$route.query.from === 'tk2'">仅供娱乐</div>
			<div style="font-size: 12px;color: #666666;">{{isvivo?'VIVO非服务提供商，服务内容与条款以广告主内容为准':''}}</div>
			<!-- <div style="font-size: 12px;color: #666666;">{{from == 'tk1'?'VIVO':'OPPO'}}非服务提供商，服务内容与条款以广告主内容为准</div> -->
		</div>
		<div v-else-if="zylj" class="webfooter" style="height:160px;font-size:10px;color:#323232">
			<div>版权所有   杭州智以类聚科技有限公司</div>
			<div>地址：浙江省杭州市余杭区五常街道期望铭座B座305</div>
			<div>电话：15695165587</div>
		</div>
		<div v-else-if="sxljkj" class="webfooter" style="height:160px;font-size:10px;color:#323232">
			<div>版权所有  绍兴炅嘉科技有限公司</div>
			<div>vivo公司非服务提供商，服务内容与服务条款以广告主内容为准</div>
			<div>浙ICP备2022006119号-1</div>
			<div>测试仅供娱乐</div>
		</div>
		<div v-else-if="wcqxhqmg" class="webfooter" style="height:160px;font-size:10px;color:#323232">
			<div>版权所有 潍城区玄海起名馆</div>
		</div>
		<div v-else-if="banhao" class="webfooter" style="height:160px;font-size:10px;color:#323232">
			<div>ICP：沪ICP备19000880号-2 </div>
		</div>
		<div v-else  class="webfooter" style="height:160px;font-size:10px;color:#323232">
			<div>版权所有   湖南亮亮文化传媒有限公司</div>
			<div>地址：长沙市开福区月湖街道鸭子铺路1号1房2室</div>
			<div>电话：18975441215</div>
		</div>

		<van-popup v-model="lookrules" style="width:90%;max-height:500px;" closeable>
			<div style="padding:20px;padding-top:20px">
				<h1 class="center">用户隐私协议</h1>
				尊敬的用户，欢迎阅读本协议：<br/><br/>

{{CopyRight}}依据本协议的规定提供服务，本协议具有合同效力。您必须完全同意以下所有条款，才能保证享受到更好的{{CopyRight}}服务。您使用服务的行为将视为对本协议的接受，并同意接受本协议各项条款的约束。<br/><br/>

用户在申请{{CopyRight}}服务过程中，需要填写一些必要的个人信息，为了更好的为用户服务，请保证提供的这些个人信息的真实、准确、合法、有效并注意及时更新。若因填写的信息不完整或不准确，则可能无法使用本服务或在使用过程中受到限制。如因用户提供的个人资料不实或不准确，给用户自身造成任何性质的损失，均由用户自行承担。<br/><br/>

保护用户个人信息是{{CopyRight}}的一项基本原则，{{CopyRight}}运用各种安全技术和程序建立完善的管理制度来保护用户的个人信息，以免遭受未经授权的访问、使用或披露。未经用户许可{{CopyRight}}不会向第三方（{{CopyRight}}控股或关联、运营合作单位除外）公开、透露用户个人信息，但由于政府要求、法律政策需要等原因除外。<br/><br/>

在用户发送信息的过程中和本网站收到信息后，本网站将遵守行业通用的标准来保护用户的私人信息。但是任何通过因特网发送的信息或电子版本的存储方式都无法确保100%的安全性。因此，本网站会尽力使用商业上可接受的方式来保护用户的个人信息，但不对用户信息的安全作任何担保。<br/><br/>

此外，您已知悉并同意：在现行法律法规允许的范围内，{{CopyRight}}科技可能会将您非隐私的个人信息用于市场营销，使用方式包括但不限于：在网页或者app平台中向您展示或提供广告和促销资料，向您通告或推荐服务或产品信息，使用电子邮件，短信等方式推送其他此类根据您使用{{CopyRight}}科技服务或产品的情况所认为您可能会感兴趣的信息。<br/><br/>

本网站有权在必要时修改服务条例，本网站的服务条例一旦发生变动，将会在本网站的重要页面上提示修改内容，用户如不同意新的修改内容，须立即停止使用本协议约定的服务，否则视为用户完全同意并接受新的修改内容。根据客观情况及经营方针的变化，本网站有中断或停止服务的权利，用户对此表示理解并完全认同。<br/><br/>

如果您还有其他问题和建议，可以通过电子邮件673681507@qq.com或者电话17620368211联系我们。

{{CopyRight}}保留对本协议的最终解释权。
		<van-button class="redbtn" @click="lookrules=false" round>关闭</van-button>
</div>
		</van-popup>
		<!-- 红包 -->
		<van-popup class="redpacket" v-model="showredPacket" >
			<div class="redpacketbox">
				<div class="center redtitile">恭喜获得红包！</div>
				<div class="center dicsountnum" style="line-height:1em;align-items: flex-end;font-size:40px;color:red;font-weight:500;margin-top:18px">
					￥{{price.discount_amount}}
					<span style="margin-left:5px;line-height:1.5em;font-size:18px">元</span>
				</div>
				<div class="center" style="margin-top:26px;font-size:14px;color:#C37D3F">使用可半价查看名字测试结果</div>
				<div class="countbox center">
					<van-count-down :time="counttime" millisecond>
						<template #default="timeData">
							<span class="block">{{ timeData.hours }}</span>
							<span class="colon">:</span>
							<span class="block">{{ timeData.minutes }}</span>
							<span class="colon">:</span>
							<span class="block">{{ timeData.seconds }}</span>
							<span class="colon">:</span>
							<span class="block">{{ parseInt(timeData.milliseconds / 10) }}</span>
						</template>	
					</van-count-down>
					<span style="color:#fff;font-size:12px;margin-left:10px;font-weight: 500;">后失效</span>
				</div>
				<van-button class="redbtn"  @click="buy"  round>点击立即使用</van-button>
				<!-- <van-button class="redbtn redbtn_2"  color="url('http://cdn1.zhiyileiju.cn/1701634698138_.pic_hd.jpg') no-repeat center" icon="http://cdn1.zhiyileiju.cn/1691634697717_.pic_hd.jpg" @click="redPackedCallback"   round>直接名师咨询</van-button> -->

			</div>	
		</van-popup>
		<van-button class="redbtn" @click="lookrules=false" round>关闭</van-button>
		<FixedRedPacket/>
		<!-- 订单确认弹框 -->
		<van-overlay :show="shopPayConfirm" z-index="10" lock-scroll>
            <div class="wrapper center" @click.stop>
                <!-- <div class="blockbx">
                    <div class="tit">请确认支付是否完成</div>
                    <div class="payed">
                        <van-button round block @click="Dcheckorder" class="Dcheckorder" type="primary">付款成功，点击查看名字</van-button>
                    </div>
                    <div class="unpay" @click="shopPayConfirm = false">
                        未支付，我再考虑一下
                    </div>
                </div> -->
				<div class="block">
                    <div class="title">
                        <p class="top_title_">离姓名解析只差1步</p>
                        <p class="bottom_title_" style="margin-top:-9px;">支付后查看姓名详批</p>
                    </div>
                    <div class="bg_box_chunk_">
                            <img :src="buttom_top_src_img" alt="">
                    </div>
                    <div class="payed" style="margin-top: 11%;" @click="getpayurls()">
                        <img src="http://cdn1.zhiyileiju.cn/WechatIMG600.jpeg" alt="">
                    </div>
                    <!-- <div class="payed" @click="unpay">
                        <img v-if="buttom_src_img_fal" :src="buttom_src_img"  alt="">

                    </div> -->
                    <div class="payed"  @click="Dcheckorder">
                        <img src="http://cdn1.zhiyileiju.cn/WechatIMG605.png" alt="">
                    </div>
                    <div class="countbox center">
                        <van-count-down :time="counttime" millisecond>
                            <template #default="timeData">
                                <span class="block_">{{ timeData.hours }}</span>
                                <span class="colon">:</span>
                                <span class="block_">{{ timeData.minutes }}</span>
                                <span class="colon">:</span>
                                <span class="block_">{{ timeData.seconds }}</span>
                                <span class="colon">:</span>
                                <span class="block_">{{ parseInt(timeData.milliseconds / 10) }}</span>
                            </template>	
                        </van-count-down>
                        <span style="color:#525252;font-size:12px;margin-left:10px;font-weight: 500;">后失效</span>
                    </div>
                </div>
            </div>
        </van-overlay>
		<!--  投诉 -->
		<span  class="fixation_complaint" v-if="complaint_buttom_fal" @click="complaintButtom">
			<img style="width:100%" src="http://cdn1.zhiyileiju.cn/WechatIMG559.pic" alt="">
		</span>
		<LookReportResultFixed/>
    </div>
</template>

<script>
	import Card from "../components/Card.vue";
    import vueSeamlessScroll from "vue-seamless-scroll";
    import axios from "../utils/request";
	import ruiDatepick from "../components/datepick/index.vue";
	import report from "../utils/stat";
	import router from "../router";
	import CountDowns from "../components/CountDowns.vue";
	import FixedRedPacket from "../components/FixedRedPacket.vue";
	import LookReportResultFixed from "../components/LookReportResultFixed.vue";

    import {
		RadioGroup,
		Radio,
		Form,
		Field,
		Toast,
		Popup,
		CountDown,
		Checkbox,
		Overlay,
	} from "vant";
    export default {
        components:{
           Card, 
           vanForm:Form,
           vanField:Field,
           vanRadioGroup:RadioGroup,
           vanRadio:Radio,
           vueSeamlessScroll,vanPopup: Popup,
		   vanCountDown:CountDown,
           ruiDatepick,
		   vanCheckbox:Checkbox,
		   vanOverlay: Overlay,
		   FixedRedPacket,
		   CountDowns,
		   LookReportResultFixed
        },
        data(){
            let currentDate = new Date();
            return {
				is_fixed_red_packets:sessionStorage.getItem('is_fixed_red_packet') === 'true' ? true : false,
				buttom_top_src_img:'http://cdn1.zhiyileiju.cn/WechatIMG610.jpeg',
           		buttom_src_img:'http://cdn1.zhiyileiju.cn/WechatIMG606.png',
				complaint_buttom_fal:true,//投诉开关
				// showredPacket: (sessionStorage.getItem('orderid') && !sessionStorage.getItem('aitestsuccess')) ? true :false,
				showredPacket: false,
				shopPayConfirm:false,
				// shopPayConfirm:true,
				counttime:10*60*1000,
				price:{},
                Form:{
                   sex: "1",
                   username:"",
                },
				CopyRight:"上海容铭信息科技有限公司",
				lookrules:false,
				checked:false,//隐私协议
                label: "请选择出生时间",
                boyStr:"",
				birth:'',
				from: this.$route.query.from,
                boyShow:false,
                fixedbtn:false,
                listData: [{
					action: "正在填写出生日期",
					city: "怒江傈僳族自治州",
					created_at: "2021-07-05 12:06:50",
					id: 1,
					time_unit: "56秒前",
					uname: "矮小扯小蚂蚁",
					updated_at: "2021-07-05 12:06:50",
				}, ],
				currentDate: currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1) + "-" + currentDate.getDate(),
				
            }
        },
        computed:{
			is_weixn() {
				var ua = navigator.userAgent.toLowerCase();
				if (ua.match(/MicroMessenger/i) == "micromessenger") {
					return true;
				} else {
					return false;
				}
       	 	},
            defaultOption() {
				return {
					step: 0.2, // 数值越大速度滚动越快
					limitMoveNum: this.listData.length,
					hoverStop: true, // 是否开启鼠标悬停stop
					direction: 1, // 0向下 1向上 2向左 3向右
					openWatch: false, // 开启数据实时监控刷新dom
					singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
					singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
					waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
				};
			},
			checking(){
				let from = this.$route.query.from;
				let allowfrom = ['ft3','d1','d2','k10','k12','k14','a10','a12','tk2','q1','tk5'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},	
			ifvo(){
				let from = this.$route.query.from;
				let allowfrom = ['tk1','tk2','tk3','tk4','tk5'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			isvivo(){
				let from = this.$route.query.from;
				let allowfrom = ['tk3','tk5'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			zylj(){
				let from = this.$route.query.from;
					let allowfrom = ['m1','t1'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			sxljkj(){
				let from = this.$route.query.from;
					let allowfrom = ['cqw1'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			  wcqxhqmg() {//潍城区玄海起名馆
				let from = this.$route.query.from;
				let allowfrom = ['cr3','cr4','cr5','cr6'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			banhao(){
				let from = this.$route.query.from;
					let allowfrom = ['cr1','cr10','cr11','cr13'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			isbjks(){
				let from = this.$route.query.from;
				let allowfrom = ['k4','k5','k6','k7','k10','k12','k14','m1','cs1','cr'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			isks4(){
				let from = this.$route.query.from;
				let allowfrom = ['d1', 'd2', 'd3', 'd4'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			ifFqudao(){
				let from = this.$route.query.from;
					let allowfrom = ['ft1', 'ft2'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			is_show(){
				let from = this.$route.query.from;
					let allowfrom = ['cqw1'];
				if (allowfrom.indexOf(from) > -1) {
					return false
				} else {
					return true
				}
			},
        },
        async created(){
			// this.showredPacket = (sessionStorage.getItem('orderid') && !sessionStorage.getItem('aitestsuccess')) ? true :false
			window.localStorage.removeItem('cm_red_packet_type');
			// console.log(this.$route.query) //获取婚姻带来的参数
			this.autoFill()//婚姻结果页跳转测名自动填充数据
            let data = await this.getactives();
			this.listData = data.data;
			document.title = "诸葛国学测名";
			let price = await axios.get(`/getSalesPrice?fromk=${this.$route.query.from}`);
			this.price = price.data.data[0] || [];
			if(price.data.data[0].discount_amount === '0.00' || price.data.data[0].discount_amount === null ) {
				this.showredPacket = false
			}else{
				this.showredPacket = (sessionStorage.getItem('orderid') && !sessionStorage.getItem('aitestsuccess')) ? true :false
			}
			// console.log('this.price ',this.price )
        },
        async mounted() {
			if (this.is_weixn) {
				let wxconfig = await this.getWxConfig();
				wx.config({
					debug: false,
					appId: wxconfig.appId,
					timestamp: wxconfig.timestamp,
					nonceStr: wxconfig.nonceStr,
					signature: wxconfig.signature,
					jsApiList: ['chooseWXPay']
				});
			}
			window.addEventListener("scroll", this.handleScroll);
			window.localStorage.setItem('fromk', this.$route.query.from);
			report("v1_testname");
			if(this.$route.query.from == 'm1' && this.$route.query.appkey){
				window.localStorage.setItem('query1', JSON.stringify(this.$route.query));
			}else{
				window.localStorage.removeItem("query1")
			}
		},
        methods:{
			async getWxConfig() {
				let url = window.location.href;

				let data = await axios.post("/payment/wechatconf", {
					url
				})
				return data.data.data.data;
			},
          	valifail(errinfo) {
				let errors = errinfo.errors[0]
				if (errors.name == "username") {
					Toast('姓名填写不正确')
				} else {
					Toast('出生日期填写不正确')
				}
			}, 

			async mcheckorder() {
				let data = await axios.post("/payment/query", {
					paytype: 2,
					order_id: sessionStorage.getItem('orderid'),
				});
				if (data.data.data.is_pay) {
					 let arr_from_ = ['pdgzhcm','pdwzcm','hygzhcm','hywzcm','qmwzcm','cm2']
                if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
						router.replace({
							name: "aitestsuccess",
							params: { id: sessionStorage.getItem('orderid'), type: data.data.data.paytype },
						}).catch(() => {});
					} else{
						router.replace({
							name: "testresult",
							params: { id: sessionStorage.getItem('orderid'), type: data.data.data.paytype },
						}).catch(() => {});
					}
				} else {
					Toast.fail("当前订单未支付");
				}
			},
			async Dcheckorder() {
				// try {
				// 	this.mcheckorder();
				// } catch (err) {
				// 	Toast.fail("当前订单未支付");
				// }

				try {
					this.mcheckorder();
					let data = await axios.post("/payment/query", {
					paytype: 2,
					order_id: sessionStorage.getItem('orderid'),
					});
					if (data.data.data.is_pay) {
						sessionStorage.setItem('aitestsuccess','1');
						 let arr_from_ = ['pdgzhcm','pdwzcm','hygzhcm','hywzcm','qmwzcm','cm2']
                if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
						router.replace({
							name: "aitestsuccess",
							params: { id: sessionStorage.getItem('orderid'), type: data.data.data.paytype },
						}).catch(() => {});
					}else{
							router.replace({
								name: "testresult",
								params: { id: sessionStorage.getItem('orderid'), type: data.data.data.paytype },
							}).catch(() => {});
						}
					} else {
						this.buy()
						// Toast.fail("当前订单未支付");
					}

				} catch (err) {
					this.buy()
					// Toast.fail("当前订单未支付");
				}

			},
			redPackedCallback () { //老师直接咨询
				location.href = "weixin://dl/business/?t=XktlC4wKEPr";
			},
			async buy(){
				window.localStorage.setItem('cm_red_packet_type',1);
				// report("v1_redpacket_click");
				await this.confirmOrder();
				let t = this;
				setTimeout(async function(){
					await t.checkorder(sessionStorage.getItem('orderid'), 2);
				},2000)
			
				// location.href = `https://api.zhiyileiju.cn/api/v1/payment/wechat/${sessionStorage.getItem('orderid')}`;
				// if ( this.$route.query.from === 'cm2' || this.$route.query.from === 'cm1' ) {
					if (this.is_weixn) {
						let payinfo = await axios.get(
							`https://hfdjh.htianwh168.com/api/v1/payment/wechat/${sessionStorage.getItem('orderid')}/wechat`)
						let pay = payinfo.data.data;

						wx.ready(function() {
							wx.chooseWXPay({
								timestamp: pay.timeStamp,
								nonceStr: pay.nonceStr,
								package: pay.package,
								signType: pay.signType,
								paySign: pay.paySign, // 支付签名
								success: function(res) {
                                         let arr_from_ = ['pdgzhcm','pdwzcm','hygzhcm','hywzcm','qmwzcm','cm2']
                						if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
											router.replace({
												name: "aitestsuccess",
												params: { id: sessionStorage.getItem('orderid'), type: data.data.data.paytype }
											}).catch(() => {});
											}else{
											router.replace({
												name: "testresult",
												params: { id: sessionStorage.getItem('orderid'), type: data.data.data.paytype }
											}).catch(() => {});
										}
								}
							});
						});
					}else {
				
						// location.href = `https://api.zhiyileiju.cn/api/v1/payment/${payway}/${orderid}`;


						location.href = `https://api.zhiyileiju.cn/api/v1/payment/wechat/${sessionStorage.getItem('orderid')}`;
					}
				 
				
				this.showredPacket = false;
				this.shopPayConfirm = true;
			},
			getpayurls () { //弹框立即付款
				// report("v1_pay_immediate_payment");
				this.buy()
			},
			async confirmOrder() {
				try {
					let aa = await axios.post("/order/confirm", {
						order_id: sessionStorage.getItem('orderid'),
						paytype: 2,
						discount_id: 1
					});
				} catch (err) {}
			},
			payclick(type){
				if (type == 'v1_testname_top_form' && this.bottom_btn_clicked) {
					report('v1_testname_b_top_form');
					return;
				}
				report(type);
			},
			
			isChn(str){
				var han = /^[\u4e00-\u9fa5]{2,8}$/; 
				 if(!han.test(str)){
				   return false ;
				} else {
				   return true ;
				}
			},

            async onSubmit(){
				window.localStorage.removeItem('cm_red_packet_type');
				let birth;
				// if(this.Form.username.length >1){
				//   Toast.fail("当前不支持双姓取名")
				//   return;
				// }
				console.log('this.birthdata.hour',this.birthdata)
				if (this.birthdata.hour) {
					birth =
						this.birthdata.year +
						"-" +
						this.birthdata.month +
						"-" +
						this.birthdata.day +
						" " +
						this.birthdata.hour +
						":00:00";
				} else {
					birth =
						this.birthdata.year +
						"-" +
						this.birthdata.month +
						"-" +
						this.birthdata.day +
						" 00:00:00";
				}
				// this.birth = birth
				let postdata = {
					birth_state:1,
					xname:this.Form.username.slice(0,1),
					name:this.Form.username,
					sex: this.Form.sex,
					pub_birth_date: birth,
					// pub_birth_date: this.birth,
					order_type: 1,
					fromk: this.$route.query.from || 10,
					openid:this.$route.query.openid === undefined ? '' : this.$route.query.openid,
					kf:this.$route.query.greenkf === undefined ? '' : this.$route.query.greenkf, 
					pageUrl: window.location.href				
				};
				if ( this.$route.query.from == 'cm2' ) {
					postdata.bind_iphone = this.$route.query.bind_iphone
				}
				
				if(this.$route.query.adid){
					postdata.adId = this.$route.query.adid
				}
				
				if(this.$route.query.requestid){
					postdata.requestId  = this.$route.query.requestid
					postdata.pageUrl = window.location.href;
				}
				if( this.$route.query.key !== undefined ) {
					postdata.key = this.$route.query.key
				}

				if ( this.$route.query.from === 'tk2' ) {
					postdata.tid = this.$route.query.tid
					postdata.lbid = this.$route.query.lbid
					postdata.pageId = this.$route.query.pageId
				}
				
				
				Toast.loading({
					message: '请稍后...',
					forbidClick: true,
				});
				
				let order = await axios.post("/addOrder", postdata);
				Toast.clear();
				router.push({
					name: "testnamePay_v2",
					query:{
						parent_order_id:this.$route.query.parent_order_id
					},
					params: {
						id: order.data.data.id,
						type: 10
					},
				}).catch(() => {});
				
            },

            bottombtnclick() {
				document
					.getElementById("agentTitle")
					.scrollIntoView({
						block: "start",
						behavior: "smooth"
					});
				this.bottom_btn_clicked = true;

			},
            handleScroll() {
				var scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				if (scrollTop > 300) {
					this.fixedbtn = true;
				} else {
					this.fixedbtn = false;
				}
			},
            boyConfirm(data) {
				this.birthdata = data;
				this.boyStr = data.thisStr;
				// console.log('23213',data)
			},
            async getactives() {
				let data = await axios.post("/ekaf", {
					type: 3,
					perPage: 10,
					page: 1,
					atype:1
				});
				return data.data;
			},
            validator(val) {
				if (val.length > 1 && val.length < 5 && this.isChn(val)) {
					return true;
				} else {
					return false;
				}
			},
            pickdata(){
			    this.boyShow = true;
            },
			autoFill () { //判断是否从婚姻结果页跳转，是的话就就自动填充用户信息
				// console.log('this',this.$route)
				// console.log('this',JSON.parse(this.$route.query.dateObj))
				// if ( this.$route.query.from == 'cm2' || this.$route.query.from == 'cm3') {
				// 	let date_ = JSON.parse(this.$route.query.dateObj)
				// 	this.boyStr = this.$route.query.birth
				// 	this.Form.sex = this.$route.query.sex
				// 	this.Form.username = this.$route.query.kf
				// 	// this.birth = `${date_.cYear}-${date_.cMonth}-${date_.cDay} ${date_.tiem_}`
				// 	localStorage.setItem("hunyin_from",this.$route.query.hunyin_from);
				// 	localStorage.setItem("hunyin_id",this.$route.query.hunyin_id);
				// 	this.birthdata = {
				// 		day: date_.cDay,
				// 		hour: date_.tiem_,
				// 		isLeap: false,
				// 		lDay: 27,
				// 		lMonth: 9,
				// 		lYear: 2021,
				// 		lunarStr: "",
				// 		month: date_.cMonth,
				// 		solarStr: "",
				// 		thisStr: "",
				// 		year: date_.cYear,
				// 	}
				// 	if( this.$route.query.immediatePayment === '1') {
				// 		this.onSubmit()
				// 	}
				// }
			},
			complaintButtom() {
            	window.location.href = 'https://work.weixin.qq.com/kfid/kfcfb085a29cbc5f94d'
        	}       
        }, 
		
    }
</script>
<style scoped>
@import "../style/main.css";

.wrapper {
    width: 100vw;
    height: 100vh;
    max-width: 640px;
    margin: 0 auto;
}


.wrapper .block {
    width: 70%;
    /* height: 70vh; */
    height: 60vh;
    background-color: white;
    border-radius: 16px;
}
.wrapper .block .title {
    line-height: 44px;
    text-align: center;
    font-size: 15px;
    margin-top: 22px;
    margin-bottom: 17px;
}
.block .title p{
    color: #CF031A;
    /* line-height: 44px; */
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    margin: 0;
}
.wrapper .bg_box_chunk_{
    overflow: hidden;
}
/* .bg_box_chunk_ */
.wrapper img{ 
    width: 100%;
}

.wrapper .countbox{
		margin-top:4%;
	}

.wrapper .colon {
	display: inline-block;
	margin: 0 4px;
	color: #d12229;
	}
	.wrapper .block_ {
	display: inline-block;
	width: 20px;
	height: 20px;
	color: #fff;
	font-size: 12px;
	text-align: center;
	background-color: #d12229;
	}
        



.wrapper .Dcheckorder {
    width: 85%;
    margin: 10px auto;
    animation: scaleDrew 1.5s ease-in-out infinite;
    font-size: 18px;
}



.wrapper .block .payed {
    width: 82%;
    margin: 0 auto;
    margin-top: 3%;
}

.wrapper .unpay {
    color: #999;
    font-size: 18px;
    text-align: center;
    line-height: 60px;
}






.blockbx {
    width: 80%;
    background-color: white;
    border-radius: 5px;
}

.Dcheckorder {
    width: 85%;
    margin: 10px auto;
    animation: scaleDrew 1.5s ease-in-out infinite;
    font-size: 18px;
}

.blockbx .tit {
    line-height: 44px;
    text-align: center;
    font-size: 15px;
    border-bottom: 1px solid #eeeeee;
}

.blockbx .payed {
    color: #b91e1e;
    font-size: 18px;
    line-height: 80px;
    text-align: center;
    border-bottom: 1px solid #eeeeee;
}

.unpay {
    color: #999;
    font-size: 18px;
    text-align: center;
    line-height: 60px;
}
.subbutton{
    width: 80vw;
    max-width: 280px;
	animation: scaleDrew 1.5s ease-in-out infinite;
	font-size: 16px;
}


.tith{
    margin: 14px auto;
    font-size: 16px;
    color: #666;
}

.title123{
	width: 70px;
}

.webfooter {
	height: 120px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	/* background: white; */
	line-height: 2em;
	font-size: 14px;
	margin-top: 10px;
	/* background: url("http://cdn1.zhiyileiju.cn/kexin.png") no-repeat top;
	background-size: 250px;
	background-color: #fff; */
}

.van-cell--mid {
		padding: 12px 16px;
	}
	
	.van-cell{
		font-size: 16px;
	}
.redpacket.van-popup {
		background: none;
	}

/* 红包 */
	.redpacketbox{
		width: 75vw;
		/* height: 108vw; */
		max-width: 300px;
		/* max-height: 432px; */
		max-height: 480px;
		padding-top: 71px;
		box-sizing: border-box;
		/* background: url('http://cdn1.zhiyileiju.cn/WechatIMG82.png') no-repeat center; */
		background: url('http://cdn1.zhiyileiju.cn/1681634696587_.pic_hd.jpg') no-repeat center;
		background-size: 100%;
		padding-bottom: 22%;
	}
	.countbox{
		margin-top:80px;
	}
	.redbtn{
		/* width: 40vw; */
		width: 76%;
		height: 45px;
		display: block;
		margin: 0 auto;
		margin-top: 20px;
		background: #FEC995;
		border: none;
		color: #C41713;
		font-size: 18px;
		font-weight: 500;
	}
	.redbtn_2{
		color: #33673b !important;
		text-indent: 16px;
	}

	.redtitile{
		color: #E38000;
		font-weight: bold;
		font-size: 18px;
	}

	.countbox{
		margin-top:80px;
	}

	.colon {
        display: inline-block;
        margin: 0 4px;
        color: #fff;
      }
      .block {
        display: inline-block;
        width: 20px;
		height: 20px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        background-color: #d12229;
      }
	  /* //投诉 */
        .fixation_complaint{
            position: fixed;width: 25px;top: 133px;right: 0;
        }

</style>

<style >
	.van-field__control::placeholder,.llable{
		color: #979797 !important;
	}
	
	.datepicker-topinfo__rightbtn{
		width: 80px;
		text-align:end;
	}
</style>
<style scoped>
.index___ .van-button__icon {
	font-size: 2em;
    line-height: inherit;
    position: absolute;
    left: -.2em;
}

</style>

<style lang="scss" scoped>
.testname{
	position: relative;
	.red_packet_payment_text_{
		position: absolute;
		white-space:nowrap;
		top: 2.35rem;
		right: 0;
		left: 1.6rem;
		text-align: center;
		font-size: .2rem;
		font-family: Source Han Sans CN;
		font-weight: 500;
		color: #8C6048;
		z-index: 1;
	}
	.red_packet_payment_text2_{
		display: flex;
		position: absolute;
		white-space:nowrap;
		top: 2.8rem;
		right: 0;
		left: 2rem;
		text-align: center;
		font-size: .24rem;
		font-family: Source Han Sans CN;
		font-weight: 500;
		color: #fff;
		z-index: 1;
		.CountDown_Componet{
			margin-left:.1rem ;
		}
	}
	.red_packet_icon_{
		position: absolute;
		width: .9rem;
		left: .2rem;
		top: 2.3rem;
		z-index: 1;
		img{
			display: block;
			width: 100%;
		}
	}
	.red_packet_frame{
		position: absolute;
		top: 1.9rem;
		width: 7.5rem;
		img{
			display: block;
			width: 100%;
		}
	}

	.p_text_{
		text-align: center;
		font-size: .24rem;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #323232;
		margin-top: .39rem;
		margin-bottom: .28rem;
		.info_text{
			font-size: .2rem;
			color: #B38C81;
		}
	}
}
</style>


