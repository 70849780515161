<template>
    <span class="count_down_">
        <div class="count_down_box_" :style="{'--count_down_FontSize':foneSize,'--count_down_Color':color}">
            <van-count-down millisecond :time="counttime" format="HH:mm:ss:SS" />
        </div>
    </span>
</template>


<script>
    export default {
        data(){
            return {
                count_down_FontSize:'.24rem',
                count_down_Color:'#fff',
                counttime:10*60*1000,//倒计时
            }
        },
        props:{
            color:{
                type:String,
                default:'#fff'
            },
            foneSize:{
                type:String,
                default:'.24rem'
            }
        },
        created(){
        }
    }
</script>

<style lang="scss" scoped>
    .count_down_{
        display: inline-block;
        .count_down_box_{
            display: inline-block;
            .van-count-down{
                font-size: var(--count_down_FontSize);
                color: var(--count_down_Color);
            }
        }
    }
</style>
