<template>
        <div v-if="isLookResultFal" class="look_report_fixed_chunk" @click="lookReportResult"><img src="https://img.nnabc.cn/202304171032.png" alt=""></div>

</template>

<script>
import axios from "../utils/request";
export default {
    data(){
        return {
            isLookResultFal:false
        }
    },
    created(){
        if ( this.$route.query.parent_order_id !== undefined ) {
            this.isLookResultFal = true
        }
    },
    computed:{
        is_weixn() {
            var ua = navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                return true;
            } else {
                return false;
            }
        },
    },
    methods:{
        async lookReportResult(){
            let data = await axios.get("/order/" + this.$route.query.parent_order_id );
            // console.log('213',data)
            let data_url = await axios.post("/const/get_front_url_host", {
                order_id: this.$route.query.parent_order_id,
            });
            console.log('data_url',data_url.data.data)
            switch ( true ) {
                case data.data.data.pageUrl.indexOf('home_marriage_test') !== -1:
                    if ( this.is_weixn ) {
                        window.location.href = `https://${data_url.data.data.hp_url_host}/home_marriage_test/payment/result_new/${this.$route.query.parent_order_id}/2?${data.data.data.pageUrl.split('?')[1]}`
                    }else{
                        window.location.href = `https://huyin.zhiyileiju.cn/home_marriage_test/payment/result_new/${this.$route.query.parent_order_id}/2?${data.data.data.pageUrl.split('?')[1]}`
                    }
                        break;
                
                    default:
                        break;
                }
        },
    }
}
</script>

<style lang="scss" scoped>
    .look_report_fixed_chunk{
        position: fixed;
        top: 2.4rem;
        left: 0;
        width: .48rem;
        img{
            display: block;
            width: 100%;
        }
    }
</style>